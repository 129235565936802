export const menuItems = [
         {
           name: '',
           label: 'Home'
         },
         { name: 'about', label: 'About' },
         { name: 'services', label: 'Services' },
         {
           name: 'programmes',
           label: 'Programmes',
           items: [
             { name: 'comptia-security', label: 'CompTIA Security+' },
             { name: 'training', label: 'Cybersecurity Training' }
           ]
         },
         { name: 'contact', label: 'Contact' }
       ];
